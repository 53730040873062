<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-container class="elevation-1">
      <v-row>
        <v-col cols="6" lg="12">
          <v-select dense
            v-model="exportConfig"
            :items="exports"
            item-text="name"
            item-value="config"
            label="Type of export"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        v-if="exportConfig.filters.showDate">
          <v-col>
            <ClarionDateControl ref="criteriaDate"
              v-model="criteria.date"
              :isRequired="true"
              label="As of Date" />
          </v-col>
      </v-row>
      <v-row v-if="isShowMonthRow">
        <v-col cols="1" v-if="exportConfig.filters.showMonth">
          <v-select
            :disabled="isDisableMonthFields"
            :items="months"
            label="Month"
            v-model.number="criteria.month"
          />
        </v-col>
        <v-col cols="1" v-if="exportConfig.filters.showMonth">
          <v-text-field
            :disabled="isDisableMonthFields"
            v-model.number="criteria.year"
            label="Year"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3" v-if="exportConfig.filters.showExportForEntireFy">
          <v-checkbox
            class="font-weight-bold"
            v-model="isExportForEntireFy"
            label="Export for entire FY"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row
        v-if="exportConfig.filters.showFromToDates">
          <v-col>
            <ClarionDateControl v-model="criteria.fromDate"
              :isRequired="true" label="From Date" />
          </v-col>
          <v-col>
            <ClarionDateControl v-model="criteria.toDate"
              :isRequired="true" label="To Date" />
          </v-col>
      </v-row>
      <v-row
        v-if="exportConfig.filters.showFiscalYears">
        <v-col>
          <FySelect
            :fyRange="fiscalYears"
            v-model="criteria.fy"
          />
        </v-col>
      </v-row>
      <v-row v-if="isShowExportBtn">
        <v-col cols="3">
          <v-btn ref="btnExport"
            @click="exportButton"
            :disabled="isDisableExport"
            target="_blank">
            Export (choose location)
          </v-btn>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <v-btn
            v-if="exportConfig.filters.showLocationBtn"
            ref="btnExportToLocation"
            @click="exportToSpecifiedLocation"
            :disabled="isDisableExport"
            target="_blank">
            Export (to the cloud)
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mdiSelectAll,
} from '@mdi/js';
import {
  mapGetters,
  mapActions,
  mapState,
} from 'vuex';

import ClarionDateControl from '../../components/common/ClarionDateControl.vue';
import FySelect from '../../components/common/FySelect.vue';

import { clients } from '../../util/clients';
import {
  destructureDate,
  toShortDate,
  toIsoDate,
  getLatestFy,
} from '../../util/shared/tmc-global';

const { backendRest } = clients.direct;

export default {
  name: 'ExportFilesOriginal',
  components: {
    ClarionDateControl,
    FySelect,
  },
  data: () => ({
    icons: {
      mdiSelectAll,
    },
    criteria: {
      date: null,
      month: null,
      year: null,
      fy: null,
      fromDate: null,
      toDate: null,
      begin: null,
      end: null,
    },
    isFormValid: false,
    isExportForEntireFy: false,
    exportConfig: {
      index: null,
      name: '',
      urlFormat: '',
      filters: {
        showDate: false,
        showMonth: false,
        showExportForEntireFy: false,
      },
    },
    token: undefined,
    overlay: false,
  }),
  created() {
    this.fetchToken();
    this.initCriteria();
    this.loadAndSetFy();
  },
  computed: {
    ...mapState('OD', [
      'odStatsFY',
    ]),
    ...mapGetters([
      'todaysDate',
    ]),
    months() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    },
    isDisableExport() {
      return this.exportConfig.filters.showFiscalYears && !this.criteria.fy;
    },
    isDisableMonthFields() {
      return this.isExportForEntireFy;
    },
    isShowMonthRow() {
      return this.exportConfig.filters.showMonth || this.exportConfig.filters.showExportForEntireFy;
    },
    monthRange() {
      let fyDateRange = {};
      if (this.isExportForEntireFy) {
        fyDateRange = this.getEntireFyDateRange(this.criteria.fy);
      } else {
        fyDateRange = this.getMonthDateRange(this.criteria.year, this.criteria.month);
      }
      return fyDateRange;
    },
    fiscalYears() {
      const fiscalYears = this.odStatsFY || [];
      // our reports don't support an "all FY" mode, so hide it
      return fiscalYears.filter((fy) => fy.year !== 0);
    },
    isShowExportBtn() {
      return this.exportConfig.urlFormat;
    },
    exportUrl() {
      return this.generateExportUrl(this.exportConfig.urlFormat);
    },
    exports() {
      return [
        {
          name: 'General Ledger Daily/CR Export (CSV)',
          config: {
            index: 1,
            urlFormat: '|BASE_URL|/export/dailyGL/|DATE|?fetchDataFrom=od,war,payroll,misc,mtg&fy=|FISCAL_YEAR|&',
            filters: {
              showDate: true,
              showFiscalYears: true,
              showFromToDates: false,
              showLocationBtn: true,
            },
            additionalExport: {
              isRequiredAdditionalExport: true,
              exportList: [
                {
                  exportName: 'Daily CR (CSV)',
                  urlFormat: '|BASE_URL|/export/daily-cr?date=|DATE|&fy=|FISCAL_YEAR|&',
                },
              ],
            },
          },
        },
        {
          name: 'General Ledger Monthly Export (CSV)',
          config: {
            index: 2,
            urlFormat: '|BASE_URL|/deptcontrols?fromDate=|MONTH_B_DATE|&toDate=|MONTH_E_DATE|&fy=|FISCAL_YEAR|&format=csv&entireFy=|ENTIRE_FY|&',
            filters: {
              showMonth: true,
              showFiscalYears: true,
              showFromToDates: false,
              showLocationBtn: true,
              showExportForEntireFy: true,
            },
          },
        },
        {
          name: 'OD Transactions Auditor Export (CSV)',
          config: {
            index: 3,
            urlFormat: '|BASE_URL|/export/odtrans-auditor-export?fromDate=|MONTH_F_DATE|&toDate=|MONTH_T_DATE|&fy=|FISCAL_YEAR|&format=csv&',
            filters: {
              showMonth: false,
              showFiscalYears: true,
              showFromToDates: true,
              showLocationBtn: false,
            },
          },
        },
        {
          name: 'OD Positive Pay Export (CSV)',
          config: {
            index: 4,
            urlFormat: '|BASE_URL|/export/odpositive-pay-export?fromDate=|MONTH_F_DATE|&toDate=|MONTH_T_DATE|&fy=|FISCAL_YEAR|&format=csv&',
            filters: {
              showMonth: false,
              showFiscalYears: true,
              showFromToDates: true,
              showLocationBtn: false,
            },
          },
        },
        {
          name: 'Balances CSV export to Clerk (CSV)',
          config: {
            index: 5,
            urlFormat: '|BASE_URL|/export/balances-export-to-clerk?asOfDate=|DATE|&fiscalYear=|FISCAL_YEAR|&format=csv&',
            filters: {
              showDate: true,
              // Todo - update it with warrant FY or global FY, currently it is using OD FY
              showFiscalYears: true,
              showLocationBtn: true,
            },
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      reAuth: 'reAuth',
      flashSuccess: 'flashSuccess',
      flashError: 'flashError',
    }),
    ...mapActions('OD', [
      'loadFiscalYears',
    ]),
    async fetchToken() {
      const { jwt } = await this.reAuth();
      this.token = `${jwt}`;
    },
    generateExportUrl(apiUrl) {
      const subsUrl = (apiUrl || '')
        .replace('|BASE_URL|', backendRest.defaults.baseURL)
        .replace('|FISCAL_YEAR|', this.criteria.fy)
        .replace('|MONTH_B_DATE|', this.monthRange.begin)
        .replace('|MONTH_E_DATE|', this.monthRange.end)
        .replace('|MONTH_F_DATE|', toIsoDate(this.criteria.fromDate))
        .replace('|MONTH_T_DATE|', toIsoDate(this.criteria.toDate))
        .replace('|DATE|', this.criteria.date)
        .replace('|ENTIRE_FY|', this.isExportForEntireFy);
      return `${subsUrl}token=${this.token}`;
    },
    initCriteria() {
      this.criteria.date = toShortDate(this.todaysDate);
      this.criteria.month = destructureDate(this.todaysDate).month;
      this.criteria.year = destructureDate(this.todaysDate).year;
      this.criteria.fromDate = toShortDate(new Date(this.todaysDate));
      this.criteria.toDate = toShortDate(new Date(this.todaysDate));
    },
    loadAndSetFy() {
      this.loadFiscalYears().then(() => {
        const latestFy = getLatestFy(this.fiscalYears);
        this.criteria.fy = latestFy;
      }).catch(() => {
        this.flashError('Facing some issue on fetching fiscal year');
      });
    },
    getEntireFyDateRange(fiscalYear) {
      const fyDateRange = {};
      fyDateRange.begin = `${fiscalYear - 1}-07-01`;
      fyDateRange.end = `${fiscalYear}-06-30`;
      return fyDateRange;
    },
    getMonthDateRange(year, month) {
      const fyDateRange = {};
      fyDateRange.begin = new Date(year, month - 1, 1)
        .toJSON().slice(0, 10);
      fyDateRange.end = new Date(year, month, 0)
        .toJSON().slice(0, 10);
      return fyDateRange;
    },
    exportToLocation(exportUrl) {
      const url = (exportUrl || '').toString();
      if (url && url !== '' && !url.match(/NaN/) && !url.match(/\/$/)) {
        window.open(url, '_blank');
      }
    },
    isRequiredAdditionalExport(exportConfig) {
      return exportConfig && exportConfig.additionalExport
        && exportConfig.additionalExport.isRequiredAdditionalExport;
    },
    exportButton() {
      this.exportToLocation(this.exportUrl);
      /* It will export multiple files on single click */
      if (this.isRequiredAdditionalExport(this.exportConfig)) {
        (this.exportConfig.additionalExport.exportList || []).forEach((item) => {
          const { urlFormat } = item;
          const exportUrl = this.generateExportUrl(urlFormat);
          this.$nextTick(() => {
            this.exportToLocation(exportUrl);
          });
        });
      }
    },
    async exportToCloud(exportUrl, { exportName }) {
      const url = `${exportUrl}&isExportToLocation=true`;
      if (url && url !== '' && !url.match(/NaN/) && !url.match(/\/$/)) {
        this.overlay = true;
        let response;
        try {
          response = await fetch(url);
        } catch (err) {
          console.error(err);
        }
        if (response && response.status === 200) {
          this.flashSuccess(`${exportName} successfully exported to the specified location`);
        } else {
          this.flashError(`${exportName} facing some issue while exporting`);
        }
        this.overlay = false;
      }
    },
    getExportNameByIndex() {
      const configIndex = this.exportConfig.index;
      const selectedExport = this.exports.find((item) => item.config.index === configIndex);
      return (selectedExport || {}).name;
    },
    async exportToSpecifiedLocation() {
      const exportName = this.getExportNameByIndex();
      this.exportToCloud(this.exportUrl, { exportName });
      /* It will export multiple files on single click */
      if (this.isRequiredAdditionalExport(this.exportConfig)) {
        (this.exportConfig.additionalExport.exportList || []).forEach((item) => {
          const { urlFormat, exportName: fileName } = item;
          const exportUrl = this.generateExportUrl(urlFormat);
          this.$nextTick(() => {
            this.exportToCloud(exportUrl, { exportName: fileName });
          });
        });
      }
    },
  },
};

</script>
